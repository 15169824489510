<template>
    <div>
        <app-top-bar title="Shift Info" left-icon="mdi-arrow-left" right-icon="mdi-refresh" @leftIconEvent="back" @rightIconEvent="get"></app-top-bar>
        <van-pull-refresh pulling-text="Pull to refresh" loosing-text="Release to refresh" loading-text="Loading..." @refresh="refresh" v-model="refreshing">
            <v-layout align-center justify-center :style="getStyle()" v-if="loading">
                <v-progress-circular indeterminate :width="3" :size="60" color="primary"></v-progress-circular>
            </v-layout>
            <v-container fluid class="pa-4 mb-4" v-if="!loading">
                <v-row>
                    <template v-if="!object.is_readonly">
                    <v-col cols="12" xs="12" sm="12" md="12" >
                        <v-alert text type="info" class="mb-0" v-if="object.shift.is_completed && object.shift.is_closed">You have finished the work</v-alert>
                        <v-alert text type="error" class="mb-0" v-if="object.over_time && !object.activity.ci.is_ci">Fail to check in on time. Contact staff for more information</v-alert>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" v-if="!isReached && !object.shift.is_closed">
                        <v-alert text type="info" class="mb-0" v-text="message" v-if="locationLoading"></v-alert>
                        <v-alert text type="error" class="mb-0" v-text="message" v-if="!locationLoading"></v-alert>
                    </v-col>
                    </template>
                    <v-col cols="12" xs="12" sm="12" md="12">
                        <v-card class="elevation-1 rounded-lg">
                            <v-card-title class="card-title" v-text="object.shift.project"></v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="pb-0">
                                <display-label label="Campaign Name" :text="object.shift.campaign"></display-label>
                            </v-card-text>
                            <v-card-text class="pb-0">
                                <display-label label="Working Date" :text="object.shift.work_date"></display-label>
                            </v-card-text>
                            <v-card-text class="pb-0">
                                <display-label label="Working Time" :text="object.shift.working_time"></display-label>
                            </v-card-text>
                            <v-card-text class="pb-0" v-if="object.break_time">
                                <display-label label="Break Hours" :text="object.shift.break_time"></display-label>
                            </v-card-text>
                            <v-card-text class="pb-0">
                                <display-label label="Outlet Name" :text="object.shift.outlet"></display-label>
                            </v-card-text>
                            <v-card-text>
                                <display-label label="Outlet Address" :text="object.shift.address"></display-label>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" v-if="object.shift.managers.length > 0">
                        <v-card class="elevation-1 rounded-lg">
                            <div :key="index" v-for="(item, index) in object.shift.managers">
                                <v-card-text :class="{'pb-0': index < object.shift.managers.length - 1}">
                                    <display-label :label="item.index" :text="item.name"></display-label>
                                </v-card-text>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12">
                        <v-list flat class="elevation-1 rounded-lg">
                            <v-list-item-group>
                                <v-list-item :ripple="false">
                                    <v-list-item-content>
                                        <v-list-item-title>Checked-In</v-list-item-title>
                                    </v-list-item-content>
                                    <v-btn small color="primary" class="elevation-0 rounded-lg" :ripple="false" @click="openStatusDialog('ci')" v-if="isReached && object.activity.ci.can_ci && !object.activity.ci.is_ci">Start Work</v-btn>
                                    <v-list-item-action v-text="object.activity.ci.time?object.activity.ci.time:'Not Yet'" v-else></v-list-item-action>
                                </v-list-item>
                                <v-list-item :ripple="false">
                                    <v-list-item-content>
                                        <v-list-item-title>Photo Attendance</v-list-item-title>
                                    </v-list-item-content>
                                    <v-btn small color="primary" class="elevation-0 rounded-lg" :ripple="false" @click="switchTab('ap')" v-if="!object.is_attendance" :disabled="!object.activity.ci.is_ci">Submit Photo</v-btn>
                                    <v-list-item-action v-text="'Yes'" v-else></v-list-item-action>
                                </v-list-item>
                                <v-list-item :ripple="false">
                                    <v-list-item-content>
                                        <v-list-item-title>Break In</v-list-item-title>
                                    </v-list-item-content>
                                    <v-btn small color="primary" class="elevation-0 rounded-lg" :ripple="false" @click="openBreakSurveyDialog('bi')" v-if="isReached && object.activity.bi.can_bi && !object.activity.ci.is_bi">Take Break</v-btn>
                                    <v-list-item-action v-text="object.activity.bi.time?object.activity.bi.time:'Not Yet'" v-else></v-list-item-action>
                                </v-list-item>
                                <v-list-item :ripple="false">
                                    <v-list-item-content>
                                        <v-list-item-title>Break Out</v-list-item-title>
                                    </v-list-item-content>
                                    <v-btn small color="primary" class="elevation-0 rounded-lg" :ripple="false" @click="openStatusDialog('bo')" v-if="isReached && object.activity.bo.can_bo && !object.activity.ci.is_bo">Return to Work</v-btn>
                                    <v-list-item-action v-text="object.activity.bo.time?object.activity.bo.time:'Not Yet'" v-else></v-list-item-action>
                                </v-list-item>
                                <v-list-item :ripple="false">
                                    <v-list-item-content>
                                        <v-list-item-title>Checked-Out</v-list-item-title>
                                    </v-list-item-content>
                                    <v-btn small color="primary" class="elevation-0 rounded-lg" :ripple="false" @click="openBreakSurveyDialog('co')" v-if="object.activity.co.can_co && !object.activity.ci.is_co">Finish Work</v-btn>
                                    <v-list-item-action v-text="object.activity.co.time?object.activity.co.time:'Not Yet'" v-else></v-list-item-action>
                                </v-list-item>
                                <v-list-item :ripple="false">
                                    <v-list-item-content>
                                        <v-list-item-title>Report Sales</v-list-item-title>
                                    </v-list-item-content>
                                    <v-btn small color="primary" class="elevation-0 rounded-lg" :ripple="false" @click="switchTab('sales')" v-if="isReached && !object.activity.sales.is_sales || object.activity.is_sales_submitted==false" :disabled="!object.activity.ci.is_ci">Submit Sales</v-btn>
                                    <v-list-item-action v-text="object.activity.sales.is_sales?'Submitted':'Not Yet'" v-else></v-list-item-action>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-col>
                </v-row>
            </v-container>
        </van-pull-refresh>
        <geo-location-reader ref="locationReader" :cache-duration="60000" @updateLocation="updateLocation" @rejectLocation="rejectLocation"></geo-location-reader>
        <status-dialog ref="statusDialog" :duration="5000" @switch-tab="switchTab" @updated="updateObject"></status-dialog>
        <tips-dialog ref="tipsDialog"></tips-dialog>
        <shift-break-survey-dialog ref="surveyDialog"  @submitted="updateSurvey" :crowdbe="crowd.crowd_flow_before_break" :crowdaf="crowd.crowd_flow_after_break"></shift-break-survey-dialog>
    </div>
</template>


<script>

import { Toast } from 'vant';
import { delay, computeGeoDistance } from '@/utils/helper'
import { redirectPathTo } from '@/utils/route'
import AppTopBar from '../../layout/AppTopBar.vue'
import DisplayLabel from '../../../shared/DisplayLabel.vue'
import GeoLocationReader from '../../../shared/GeoLocationReader.vue'
import ShiftStatusDialog from '../../shift/activity/ShiftStatusDialog.vue'
import ShiftLocationTipsDialog from '../../shift/activity/ShiftLocationTipsDialog.vue'
import ShiftBreakSurveyDialog from '../../shift/activity/ShiftBreakSurveyDialog.vue'

export default {
    name: 'ShiftActivityPanel',
    components: {
        appTopBar: AppTopBar,
        displayLabel: DisplayLabel,
        geoLocationReader: GeoLocationReader,
        statusDialog: ShiftStatusDialog,
        tipsDialog: ShiftLocationTipsDialog,
        shiftBreakSurveyDialog: ShiftBreakSurveyDialog,
    },
    props: {
        code: {
            type: String,
            required: false,
            default: ''
        },
        salesUpdate: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            loading: true,
            locationLoading: true,
            isReached: false,
            refreshing: false,
            status: '',
            message: 'Fetching your location...',
            object: {
                is_readonly: true,
                is_attendance: '',
                shift: { location: {}, managers: [] },
                activity: {
                    ci: {},
                    bi: {},
                    bo: {},
                    co: {},
                    sales: {}
                }
            },
            crowd: {},
        }
    },
    computed: {
        locationReader() {
            return this.$refs.locationReader
        },
        statusDialog() {
            return this.$refs.statusDialog
        },
        tipsDialog() {
            return this.$refs.tipsDialog;
        },
        surveyDialog() {
            return this.$refs.surveyDialog
        }
    },
    mounted: function() {
        this.get()
    },
    methods: {
        back: function() {
            redirectPathTo(this.$router, this.$route.meta.back_url)
        },
        get: function(loading=true) {
            this.loading = loading
            delay(() => {
                if(this.code){
                    this.$store.dispatch('mobile/shift/getShiftActivity', { 'code': this.code }).then((response) => {
                        this.loading = false
                        this.object = response.data.object
                        this.crowd = response.data.object.break_choices
                        this.is_attendance = response.is_attendance
                        this.getLocation()
                    }).catch(() => {
                        this.loading = false
                    })
                }else{
                    this.back()
                }
            }, 500)
		},
		getLocation: function() {
            if(!this.object.is_readonly){
                this.locationReader.getLocation()
            }
		},
		refresh() {
            delay(() => {
                this.get(false)
                this.refreshing = false
            }, 1000)
        },
		reset: function() {
            this.loading = true
            this.refreshing = false
        },
        openStatusDialog: function(status) {
            this.statusDialog.updateObject({shift: this.code, status: status})
            this.statusDialog.open()
            this.refresh()
        },
        openBreakSurveyDialog: function(status) {
            this.status = status
            this.surveyDialog.updateObject({shift: this.code, status: status})
            this.surveyDialog.open()
        },
        updateObject: function(item) {
            this.$set(this.object, 'shift', item.shift)
            this.$set(this.object, 'activity', item.activity)
            Toast.success()

            if(item.shift.is_completed){
                console.log('cp 4')
                this.$emit('completed')
            }
		},
        updateSurvey: function(item){
            if(item.status=='bi'){
                this.surveyDialog.close()
                this.statusDialog.updateObject({shift: this.code, status: item.status, 
                                                crowd_flow_before_break_feedback: item.crowd_flow_before_break_feedback})
            }
            else if(item.status=='co'){
                this.surveyDialog.close()
                this.statusDialog.updateObject({shift: this.code, status: item.status, 
                                                crowd_flow_after_break_feedback: item.crowd_flow_after_break_feedback})
            }
            
            this.statusDialog.open()
        },
        getStyle: function() {
            return {"min-height": (window.innerHeight - 56).toString() + "px"}
        },
        getGapDistanceText: function() {
            return this.distance.toString() + " Meters."
        },
        updateLocation: function(item) {
            const location = this.object.shift.location
            if(location.lat > 0.0 && location.lng > 0.0){
                this.locationLoading = false
                const distance = computeGeoDistance(location.lat, location.lng, item.lat, item.lng, 'm')
                if(distance > location.allowed_distance){
                    this.message = "You are far away from the outlet around " + distance + " meters. Please try it later"
                }else{
                    this.isReached = true
                }
            }else{
                this.locationLoading = true
                this.message = "Unable to fetch your location. Please try it later"
                this.tipsDialog.open();
            }
        },
        rejectLocation: function() {
            this.message = "Unable to detect the location. Please try it later"
            this.tipsDialog.open();
        },
        isSalesRequired: function() {
            return this.object.activity.bo.is_bo && !this.object.activity.sales.is_sales
        },
        switchTab: function(status) {
            if(status=='sales'){
                this.$emit('switch-tab', 2);
            }
            else if (status=='ap'){
                this.$emit('switch-tab', 1);
            }
        },
        switchAttendanceTab: function() {
            this.$emit('switch-tab', 1);
        }
    }
}

</script>


<style scoped>

.card-title {
    font-weight: normal !important;
}

/deep/ .text-label {
    font-weight: 600 !important;
}

/deep/ .text-label, /deep/ .text-title {
    font-size: 1rem !important;
    letter-spacing: 0.009375em !important;
    color: rgba(0, 0, 0, 0.87)
}

</style>