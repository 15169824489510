<template>
    <div>
        <app-top-bar title="Feedbacks" left-icon="mdi-arrow-left" :right-icon="'mdi-plus'" @leftIconEvent="back" @rightIconEvent="openCreateDialog"></app-top-bar>
        <van-pull-refresh pulling-text="Pull to refresh" loosing-text="Release to refresh" loading-text="Loading..." @refresh="refresh" v-model="refreshing">
            <v-layout align-center justify-center :style="getStyle()" v-if="loading">
                <v-progress-circular indeterminate :width="3" :size="60" color="primary"></v-progress-circular>
            </v-layout>
            <div v-if="!loading">
                <v-layout align-center justify-center :style="getStyle()" v-if="items.length === 0">
                    <p v-text="message"></p>
                </v-layout>
                <v-container fluid class="pa-0" :style="getStyle()" v-if="items.length > 0">
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12">
                            <v-sheet :key="index" :ripple="false" class="sheet px-4 py-2 elevation-0" v-for="(item, index) in items" @click="openDeleteDialog(item, index)">
                                <p class="mb-1 text-subtitle-2 grey--text lighten--4" v-text="item.created_on"></p>
                                <p class="text-message text-subtitle-2 my-3" v-text="item.message"></p>
                                <p class="d-flex mb-1" v-if="item.images.length > 0">
                                    <v-img width="50" max-width="50" :src="image.thumbnail_url" :key="sindex" class="mx-2" v-for="(image, sindex) in item.images"></v-img>
                                </p>
                            </v-sheet>
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </van-pull-refresh>
        <create-dialog ref="createDialog" :code="code" @created="addFeedback"></create-dialog>
        <delete-dialog ref="deleteDialog" :deletable="!object.is_closed" @deleted="deleteFeedback"></delete-dialog>
    </div>
</template>


<script>

import { delay } from '@/utils/helper'
import { redirectPathTo } from '@/utils/route'
import AppTopBar from '../../layout/AppTopBar.vue'
import ShiftFeedbackCreateDialog from './ShiftFeedbackCreateDialog.vue'
import ShiftFeedbackDeleteDialog from './ShiftFeedbackDeleteDialog.vue'

export default {
    name: 'ShiftFeedbackPanel',
    components: {
        appTopBar: AppTopBar,
        createDialog: ShiftFeedbackCreateDialog,
        deleteDialog: ShiftFeedbackDeleteDialog,
    },
    props: {
        code: {
            type: String,
            required: false,
            default: ''
        },
        forceUpdate: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            loading: true,
            refreshing: false,
            selectedIndex: -1,
            message: '',
            items: [],
            object: {
                is_closed: true
            }
        }
    },
    computed: {
        createDialog() {
            return this.$refs.createDialog
        },
        deleteDialog() {
            return this.$refs.deleteDialog
        }
    },
    mounted: function() {
        this.get()
    },
    methods: {
        back: function() {
            redirectPathTo(this.$router, this.$route.meta.back_url)
        },
        get: function(loading=true) {
            this.loading = loading
            delay(() => {
                this.$store.dispatch('mobile/shift/getShiftFeedbacks', { code: this.code }).then((response) => {
                    this.loading = false
                    this.items = response.data.items
                    this.object = response.data.object
                    this.message = response.message
                }).catch(() => {
                    this.loading = false
                })
            }, 500)
		},
		refresh() {
            delay(() => {
                this.get(false)
                this.refreshing = false
            }, 1000)
        },
        reset: function() {
            this.loading = true
        },
        getStyle: function() {
            return {"min-height": (window.innerHeight - 56).toString() + "px"}
        },
        openCreateDialog: function(){
            this.createDialog.open()
        },
        openDeleteDialog: function(item, index) {
            this.selectedIndex = index
            this.deleteDialog.updateObject(item)
            this.deleteDialog.open()
        },
        addFeedback: function(item) {
            this.items.splice(0, 0, item)
        },
        deleteFeedback: function(){
            this.items.splice(this.selectedIndex, 1)
        }
    },
    watch: {
        forceUpdate: function(newValue){
            if(newValue && !this.object.is_closed){
                this.get()
            }
        }
    }
}

</script>