<template>
    <div>
        <v-tabs-items touchless v-model="tab" :key="updates">
            <v-tab-item><activity-panel :code="object.code" :salesUpdate="sales_update" @completed="updateForceUpdate" @switch-tab="changeBottomTab"></activity-panel></v-tab-item>
            <v-tab-item><attendance-panel :code="object.code" :force-update="forceUpdate"></attendance-panel></v-tab-item>
            <v-tab-item><sales-panel @submitSales="updateSalesReport" :code="object.code" :force-update="forceUpdate"></sales-panel></v-tab-item>
            <v-tab-item><feedback-panel :code="object.code" :force-update="forceUpdate"></feedback-panel></v-tab-item>
            <v-tab-item><reference-panel :code="object.code"></reference-panel></v-tab-item>
        </v-tabs-items>
        <app-bottom-bar ref="bottomBar" :tab-items="items" @changed="changeBottomTab"></app-bottom-bar>
    </div>
</template>


<script>

import AppBottomBar from '../layout/AppBottomBar.vue'
import ShiftActivityPanel from './activity/ShiftActivityPanel.vue'
import ShiftSalesPanel from './sales/ShiftSalesPanel.vue'
import ShiftAttendancePanel from './attendance/ShiftAttendancePanel.vue'
import ShiftFeedbackPanel from './feedback/ShiftFeedbackPanel.vue'
import ShiftReferencePanel from './reference/ShiftReferencePanel.vue'
import Cookies from 'js-cookie'

export default {
    name: 'TodayShiftDashboard',
    components: {
        appBottomBar: AppBottomBar,
        activityPanel: ShiftActivityPanel,
        salesPanel: ShiftSalesPanel,
        attendancePanel: ShiftAttendancePanel,
        feedbackPanel: ShiftFeedbackPanel,
        referencePanel: ShiftReferencePanel,
    },
    data() {
        return {
            tab: 0,
            object: {},
            sales_update: false,
            forceUpdate: false,
            items: [
                { text: 'Shift', title: 'Shift Info', icon: 'mdi-information-outline', route: 'mobile-shift-info' },
                { text: 'Attendance', title: 'Attendance', icon: 'mdi-camera', route: 'mobile-shift-attendance' },
                { text: 'Sales', title: 'Sales', icon: 'mdi-format-list-bulleted-square', route: 'mobile-shift-sales' },
                { text: 'Feedback', title: 'Feedbacks', icon: 'mdi-message-reply-text', route: 'mobile-shift-feedback' },
                { text: 'Reference', title: 'References', icon: 'mdi-file-document-outline', route: 'mobile-shift-document' }
            ],
            updates: 0
        }
    },
    computed: {
        bottomBar() {
            return this.$refs.bottomBar
        }
    },
    mounted: function() {
        this.check_accessToken_validity()
        this.tab = 0
        this.bottomBar.reset()
        this.updateObject(this.$route.params)
    },
    methods: {
        updateObject: function(item) {
            this.object = item
        },
        updateForceUpdate: function() {
            this.forceUpdate = true
        },
        changeBottomTab: function(tab) {
            this.tab = tab
            this.bottomBar.updateTab(tab);
            this.forcePageUpdate()
        },
        updateSalesReport: function() {
            this.sales_update = true
        },
        forcePageUpdate: function () {
            this.updates += 1;
            this.check_accessToken_validity()
        },
        check_accessToken_validity: function() {
            let localstorage_data = JSON.parse(localStorage.getItem("blink-data"));
            let token_expiry_time = new Date(localstorage_data.user.expiry_time * 1000);
            let current_time = new Date()

            if(current_time > token_expiry_time) {
                this.delete_accessToken()
                this.$router.push('/login')
            }
        },
        delete_accessToken: function() {
            Cookies.remove('access-token', 'value', { path: '/' })
        }
    },
    watch: {
        "$route": function(to){
            if(['mobile-today-shift', 'mobile-upcoming-shift', 'mobile-worked-shift'].indexOf(to['name']) > -1){
                this.updateObject(to['params'])
            }
        }
    }
}

</script>


<style scoped>

</style>