<template>
    <div>
        <v-dialog persistent max-width="764px" v-model="dialog">
            <v-card>
                <v-card-title class="justify-center">Feedback</v-card-title>
                <v-col class="pb-0 pt-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <display-label label="Is working location favourable?"></display-label>
                    <display-label label="店内分配的位置是否适合工作 ?"></display-label>
                    <v-radio-group row class="mt-3" v-model="object.answer">
                        <v-radio :key="index" @click="clearCheckbox('l')" :ripple="false" :label="option.text" :value="option.value" v-for="(option, index) in general_choice"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col class="pb-0 pt-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="object.answer=='N'">
                    <display-label label="Why? (You may select more than one option)"></display-label>
                    <div v-for="(option, index) in booth_location_feedback" :key="index">
                        <v-checkbox v-model="object.feedback" :key="index" :ripple="false" :label="option.text" :value="option.value"></v-checkbox>
                        <div class="cn-label">
                            <label :label="option.cn_text">{{option.cn_text}}</label>
                        </div>
                    </div>
                </v-col>
                <v-col class="pb-0 pt-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <display-label label="Is there competitor promoters around?"></display-label>
                    <display-label label="有来自竞争对手品牌的促销员吗 ?"></display-label>
                    <v-radio-group row class="mt-3" v-model="object.is_competitor">
                        <v-radio class="mb-5" :key="index" :ripple="false" :label="option.text" :value="option.value" v-for="(option, index) in general_choice"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col class="pb-0 pt-0 mb-5" cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="object.is_competitor=='Y'">
                    <display-label label="Which brand?"></display-label>
                    <display-label label="如果有其他品牌，请添加品牌竞争对手"></display-label>
                    <div v-for="(textField, i) in textFields" :key="i" class="text-fields-row mt-5" >
                        <v-text-field label="Brand Name (品牌)" v-model="textField.value"></v-text-field>
                        <v-btn small @click="remove(i)" class="error elevation-0 rounded-lg">delete (删除)</v-btn>
                    </div>
                    <v-btn small color="primary" class="elevation-0 rounded-lg mt-3" @click="add" :ripple="false">Add competitor brand</v-btn>
                </v-col>
                <v-col class="pb-0 pt-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <display-label label="Is there competitor running a promotion?"></display-label>
                    <display-label label="竞争对手是否在进行促销活动?"></display-label>
                    <v-radio-group row class="mt-3" v-model="object.is_competitor_promotion">
                        <v-radio class="mb-5" :key="index" :ripple="false" :label="option.text" :value="option.value" v-for="(option, index) in general_choice"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col class="pb-0 pt-0 mb-5" cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="object.is_competitor_promotion=='Y'">
                    <display-label label="Which brand?"></display-label>
                    <display-label label="哪个品牌?"></display-label>
                    <div class="mt-5" v-for="(competitotTextField, i) in competitorPromotionTextFields" :key="i">
                        <v-text-field label="Brand Name (品牌)" v-model="competitotTextField.brand"></v-text-field>
                        <v-text-field label="Promotion (促销)" v-model="competitotTextField.promotion"></v-text-field>
                        <v-btn small @click="removeCompetitorPromotion(i)" class="error elevation-0 rounded-lg">delete (删除)</v-btn>
                    </div>
                    <v-btn small color="primary" class="elevation-0 rounded-lg mt-3" @click="addCompetitorPromotion" :ripple="false">Add competitor (添加竞争对手)</v-btn>
                </v-col>

                <v-card-actions class="">
                    <v-btn text @click="close">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="red" :disabled="!object.answer || !object.is_competitor || !object.is_competitor_promotion" @click="confirm" v-text="confirmButtonText"></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DisplayLabel from '../../../shared/DisplayLabel.vue'

export default {
    name: 'ShiftLocationQuestionDialog',
    components: {
        displayLabel: DisplayLabel,
    },
    props: {
        confirmButtonText: {
            type: String,
            required: true,
            default: ''
        },
        general_choice: {
            type: Array,
            required: true,
            default: () => []
        },
        booth_location_feedback: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            dialog: false,
            object: {
                answer: '',
                feedback: [],
                is_competitor: '',
                competitor_brand: [],
                is_competitor_promotion: '',
            },
            textFields: [],
            competitorPromotionTextFields: [],
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
            this.$emit('closed')
        },
        confirm: function(){
            for(let i=0; i<this.textFields.length; i++){
                this.object.competitor_brand.push(this.textFields[i].value)
            }
            this.dialog = false
            this.$emit('confirmed', {object: this.object.answer, feedback: this.object.feedback, 
                                    is_competitor: this.object.is_competitor, 
                                    competitor_brand: this.object.competitor_brand, 
                                    is_competitor_promotion: this.object.is_competitor_promotion,
                                    competitor_promotion: JSON.stringify(this.competitorPromotionTextFields)})
        },
        add: function() {
            this.textFields.push({ 
                value: ''
            })
        },
        remove (index) {
         this.textFields.splice(index, 1)
        },
        addCompetitorPromotion: function() {
            this.competitorPromotionTextFields.push({
                brand: "",
                promotion: ""
            })
        },
        removeCompetitorPromotion (index) {
            this.competitorPromotionTextFields.splice(index, 1)
        },
        clearCheckbox: function(item) {
            if(item=='l') {
                this.object.feedback = []
            }
        }
    }
}

</script>

<style scoped>

.text-fields-row {
  display: flex;
}

.v-text-field {
    padding-top: 0px !important;
    margin-top: 0px !important;
}

.cn-label {
    padding-left: 30px;
    margin-top: -15px;
}

</style>