<template>
    <div>
        <app-top-bar title="References" left-icon="mdi-arrow-left" @leftIconEvent="back"></app-top-bar>
        <van-pull-refresh pulling-text="Pull to refresh" loosing-text="Release to refresh" loading-text="Loading..." @refresh="refresh" v-model="refreshing">
            <v-layout align-center justify-center :style="getStyle()" v-if="loading">
                <v-progress-circular indeterminate :width="3" :size="60" color="primary"></v-progress-circular>
            </v-layout>
            <div v-if="!loading">
                <v-layout align-center justify-center :style="getStyle()" v-if="items.length === 0">
                    <p>No reference available yet</p>
                </v-layout>
                <v-container fluid class="pa-0" :style="getStyle()" v-if="items.length > 0">
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12">
                            <v-list two-line>
                                <div :key="index" v-for="(item, index) in items">
                                    <v-list-item :href="item.file_url" target="_blank" v-if="item.file_url">
                                        <v-list-item-avatar>
                                            <v-icon large>mdi-file-document-outline</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.name"></v-list-item-title>
                                            <v-list-item-subtitle v-text="item.updated_on"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                </div>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </van-pull-refresh>
    </div>
</template>


<script>

import { delay } from '@/utils/helper'
import { redirectPathTo } from '@/utils/route'
import AppTopBar from '../../layout/AppTopBar.vue'

export default {
    name: 'ShiftReferencePanel',
    components: {
        appTopBar: AppTopBar
    },
    props: {
        code: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            loading: true,
            refreshing: false,
        }
    },
    mounted: function() {
        this.get()
    },
    methods: {
        back: function() {
            redirectPathTo(this.$router, this.$route.meta.back_url)
        },
        get: function(loading=true) {
            this.loading = loading
            delay(() => {
                this.$store.dispatch('mobile/shift/getShiftResources', { code: this.code }).then((response) => {
                    this.loading = false
                    this.items = response.data.items
                    this.object = response.data.object
                }).catch(() => {
                    this.loading = false
                })
            }, 500)
		},
		refresh() {
            delay(() => {
                this.get(false)
                this.refreshing = false
            }, 1000)
        },
        getStyle: function() {
            return {"min-height": (window.innerHeight - 56).toString() + "px"}
        }
    },
    watch: {
        code: function(){
            this.get()
        }
    }
}

</script>