<template>
    <v-dialog persistent max-width="400" v-model="dialog">
        <v-card class="rounded-xl">
            <v-card-title><v-spacer class="text-center">Blink System</v-spacer></v-card-title>
            <v-card-text class="py-2 text-center">
                <span class="text-subtitle-1" v-text="getText()"></span>
            </v-card-text>
            <v-card-actions class="justify-space-around">
                <v-btn text :ripple="false" @click="close">Later</v-btn>
                <v-btn text color="primary" :ripple="false" @click="save" v-text="getButtonText()"></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>

export default {
    name: 'ShiftStatusDialog',
    data() {
        return {
            dialog: false,
            object: {
                shift: '',
                status: '',
                crowd_flow_before_break_feedback: '',
                crowd_flow_after_break_feedback: '',
            },
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
        },
        save: function() {
            if(this.object.status === 'sales'){
                this.$emit('switch-tab', 2)
                this.close()
            }else{
                this.$store.dispatch('mobile/shift/updateShiftStatus', this.object).then((response) => {
                    this.$emit('updated', response.data.object)
                    this.close()
                }).catch(() => {
                    this.statusLoading = false
                })
            }
        },
        updateObject: function(obj){
            this.object = obj
        },
        getText: function(){
            const texts = { "ci": "Are you sure that you want to start the work from now?",
                            "co": "Are you sure that you want to finish the work right now?",
                            "bi": "Are you sure that you want to take a break now?",
                            "bo": "Are you sure that you want to return to work from now?",
                            "sales": "You have not submitted your sales yet", "": ""};
            return texts[this.object.status]
        },
        getButtonText: function() {
            const texts = { "ci": "Start Work", "co": "Finish Work", "bi": "Take Break", "bo": "Return to Work",
                            "sales": "Go to submit", "": ""}
            return texts[this.object.status]
        }
    }
}

</script>