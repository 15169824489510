<template>
    <div>
        <v-bottom-navigation app grow color="primary" v-model="tab" >
            <v-btn :to="item.to" :key="index" v-for="(item, index) in items" @click="changeTabEvent(index)">
                <span v-text="item.text"></span>
                <v-icon v-text="item.icon"></v-icon>
            </v-btn>
        </v-bottom-navigation>
    </div>
</template>


<script>
import Cookies from 'js-cookie'

export default {
    name: 'AppBottom',
    props: {
        activeTab: {
            type: Number,
            required: false,
            default: -1
        },
        tabItems: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            tab: 0,
            items: [
                { text: 'Today', icon: 'mdi-calendar-check', to: '/today' },
                { text: 'Upcoming', icon: 'mdi-format-list-bulleted-square', to: '/upcoming' },
                { text: 'Account', icon: 'mdi-account', to: '/me' }
            ]
        }
    },
    mounted: function() {
        this.check_accessToken_validity()
        this.updateTab()
    },
    methods: {
        updateTab: function(tab) {
            if(tab && tab > -1) {
                this.tab = tab;
            } else if(this.activeTab > -1) {
                this.tab = this.activeTab
            }

            if(this.tabItems.length > 0){
                this.items = this.tabItems
            }
        },
        reset: function() {
            this.tab = 0
            this.activeTab = -1
        },
        updateItems: function(items) {
            this.items = items
        },
        changeTabEvent: function(tab) {
            this.$emit("changed", tab)
        },
        check_accessToken_validity: function() {
            let localstorage_data = JSON.parse(localStorage.getItem("blink-data"));
            let token_expiry_time = new Date(localstorage_data.user.expiry_time * 1000);
            let current_time = new Date()

            if(current_time > token_expiry_time) {
                this.delete_accessToken()
                this.$router.push('/login')
            }
        },
        delete_accessToken: function() {
            Cookies.remove('access-token', 'value', { path: '/' })
        }
    }
}

</script>

