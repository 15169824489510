<template>
    <div>
        <app-top-bar title="Sales" left-icon="mdi-arrow-left" right-icon="mdi-refresh" @leftIconEvent="back" @rightIconEvent="get"></app-top-bar>
        <van-pull-refresh pulling-text="Pull to refresh" loosing-text="Release to refresh" loading-text="Loading..." @refresh="refresh" v-model="refreshing">
            <v-layout align-center justify-center :style="getStyle()" v-if="loading">
                <v-progress-circular indeterminate :width="3" :size="60" color="primary"></v-progress-circular>
            </v-layout>
            <v-layout align-center justify-center :style="getStyle()" v-if="!loading && items.length === 0">
                <p v-text="message"></p>
            </v-layout>
            <v-container fluid class="pa-0 pb-4" :style="getStyle()" v-if="!loading && items.length > 0">
                <v-row v-if="!object.is_readonly && object.is_closed && message">
                    <v-col cols="12" xs="12" sm="12" md="12" class="mb-4">
                        <v-alert text type="info" class="mb-0 text-center" v-text="message"></v-alert>
                    </v-col>
                </v-row>
                <v-list flat three-lines>
                    <v-list-item :class="{'disabled': object.is_sales_submitted, 'updated-item': item.is_updated}" :key="index" :ripple="false" v-for="(item, index) in items" @click.prevent="openUpdateDialog(item, index)">
                        <v-list-item-avatar size="80" class="mr-0" v-if="item.image_url">
                            <v-img contain :src="item.image_url"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="item-title mb-2" v-text="item.name"></v-list-item-title>
                            <v-list-item-title class="item-title" v-text="item.price"></v-list-item-title>
                        </v-list-item-content>
                        <div>
                            <div class="no-input" v-if="item.is_updated === false">
                                <v-list-item-action class="justify-center align-center">
                                    <span>Insert</span><span>sales</span>
                                </v-list-item-action>
                            </div>
                            <div v-else>
                                <v-list-item-action class="justify-center align-center">
                                    <span v-text="item.quantity"></span>
                                    <span>Sold</span>
                                </v-list-item-action>
                            </div>
                        </div>
                    </v-list-item>
                </v-list>
                <div class="sales-report-submit-btn">
                    <div :class="{'message-wrapper': checker}">
                        <div class="message-separator">
                            <p class="error-msg"> *Please fill in the sales for each SKU to proceed with report submission </p>
                            <p class="error-msg"> *If there is no sales for particular SKU, please fill in "0" </p>
                        </div>
                        <div class="message-separator"> 
                            <p class="error-msg"> *请填写每个库存量单位的销售额以继续提交报告 </p>
                            <p class="error-msg"> *如果没有特定库存量单位的销售，请填写“0” </p>
                        </div>
                    </div>
                    <v-btn color="primary" class="elevation-0 rounded-lg wide-btn" :class="{'disabled': !checker}" @click="openSurveyDialog('bo')">Submit</v-btn>
                </div>
            </v-container>
        </van-pull-refresh>
        <update-dialog ref="updateDialog" @updated="updateItem"></update-dialog>
        <shift-sales-confirmation-dialog @confirmed="confirmSubmit" @submitted="get" ref="confirmationDialog" title="Submit Sales Report" message="Do you want to submit this sales report? Once you submit this sales report, you are no longer to edit it again." confirm-button-text="Submit"></shift-sales-confirmation-dialog>
        <shift-sales-survey-dialog ref="surveyDialog" :general_choice="general_choice" :booth_location_feedback="booth_location_feedback" :promotion="promotion" :reason="reason" :awareness="awareness" @submitted="submitSalesReport"></shift-sales-survey-dialog>
        <tips-dialog ref="tipsDialog"></tips-dialog>
    </div>
</template>


<script>

import { delay } from '@/utils/helper'
import { redirectPathTo } from '@/utils/route'
import AppTopBar from '../../layout/AppTopBar.vue'
import ShiftSalesUpdateDialog from '../../shift/sales/ShiftSalesUpdateDialog.vue'
import ShiftSalesTipsDialog from '../../shift/sales/ShiftSalesTipsDialog.vue'
import ShiftSalesConfirmationDialog from './ShiftSalesConfirmationDialog'
import ShiftSalesSurveyDialog from './ShiftSalesSurveyDialog'

export default {
    name: 'ShiftSalesPanel',
    components: {
        appTopBar: AppTopBar,
        updateDialog: ShiftSalesUpdateDialog,
        tipsDialog: ShiftSalesTipsDialog,
        shiftSalesConfirmationDialog: ShiftSalesConfirmationDialog,
        shiftSalesSurveyDialog: ShiftSalesSurveyDialog
    },
    props: {
        code: {
            type: String,
            required: false,
            default: ''
        },
        forceUpdate: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            loading: true,
            refreshing: false,
            selectedIndex: -1,
            sales_update: false,
            message: '',
            items: [],
            object: {
                is_readonly: true,
                is_promotion: '',
                location: '',
                brand_awareness: '',
                location_feedback: [],
                promotion_feedback: [],
            },
            general_choice: [],
            booth_location_feedback: [],
            promotion: [],
            reason: [],
            awareness: [],
            sku_updated: [],
            checker: false,
            check_in: false
        }
    },
    computed: {
        updateDialog() {
            return this.$refs.updateDialog
        },
        tipsDialog() {
            return this.$refs.tipsDialog
        },
        confirmationDialog() {
            return this.$refs.confirmationDialog
        },
        surveyDialog() {
            return this.$refs.surveyDialog
        }
    },
    mounted: function() {
        this.get()
    },
    methods: {
        back: function() {
            redirectPathTo(this.$router, this.$route.meta.back_url)
        },
        get: function(loading=true) {
            this.loading = loading
            delay(() => {
                this.$store.dispatch('mobile/shift/getShiftSales', { code: this.code }).then((response) => {
                    this.loading = false
                    this.items = response.data.items
                    this.object = response.data.object
                    this.message = response.message
                    this.general_choice = response.data.object.general_choices
                    this.booth_location_feedback = response.data.object.booth_location_feedback
                    this.promotion = response.data.object.promotion_choices
                    this.reason = response.data.object.promotion_bad_reason_choices
                    this.awareness = response.data.object.brand_awareness_choices
                    this.check_in = response.data.object.is_ci

                    //reset sku array to empty array
                    this.sku_updated = []

                    this.items.forEach(items => {
                        let sku_status = items.is_updated
                        this.sku_updated.push(sku_status)
                    });
                    this.checker = this.trueChecker(this.sku_updated);

                    if(!this.object.is_closed && !this.object.is_readonly){
                        this.tipsDialog.open();
                    }
                }).catch(() => {
                    this.loading = false
                })
            }, 500)
		},
		refresh() {
            delay(() => {
                this.get(false)
                this.refreshing = false
            }, 1000)
        },
        reset: function() {
            this.loading = true
        },
        getStyle: function() {
            return {"min-height": (window.innerHeight - 112).toString() + "px"}
        },
        openUpdateDialog: function(item, index) {
            if(this.check_in) {
                this.selectedIndex = index
                this.updateDialog.updateObject(item)
                this.updateDialog.open()
            }
        },
        updateItem: function(item){
            this.$set(this.items, this.selectedIndex, item)
            this.sku_updated = []

            this.items.forEach(items => {
                let sku_status = items.is_updated
                this.sku_updated.push(sku_status)
            });

            this.checker = this.trueChecker(this.sku_updated);
        },
        openConfirmationDialog: function() {
            this.confirmationDialog.open()
        },
        openSurveyDialog: function(status) {
            this.surveyDialog.updateObject({shift: this.code, status: status})
            this.surveyDialog.open()
        },
        submitSalesReport: function(item) {
            this.surveyDialog.close()
            this.openConfirmationDialog()
            this.update_sales = true
            this.object.is_promotion = item.is_promotion
            this.object.promotion_feedback = item.promotion_feedback
            this.object.location = item.location
            this.object.location_feedback = item.location_feedback
            this.object.brand_awareness = item.brand_awareness
        },
        confirmSubmit: function(item){
            const object = {'code': this.code, 'update_sales': this.update_sales, 'is_promotion': this.object.is_promotion, 
                            'promotion_feedback': this.object.promotion_feedback, 'location': this.object.location, 
                            'location_feedback': this.object.location_feedback, 'brand_awareness': this.object.brand_awareness}
            if(item.is_submit){
                this.$store.dispatch('mobile/shift/updateShiftSurvey', object).then((response) => {
                    this.submitting = false
                    this.$emit('submitted', response.data.object)
                    this.get()
                }).catch(() => {
                    this.submitting = false
                })
                this.$emit('updated', object)
            }
        },
        trueChecker: function(arr) {
            let checked = arr.every(val => val === true);
            return checked
        }
    },
    watch: {
        forceUpdate: function(newValue){
            if(newValue && !this.object.is_closed){
                this.get()
            }
        }
    }
}

</script>


<style scoped>
.item-title {
    display: block !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.sales-report-submit-btn {
    text-align: center !important;
}

.sales-report-submit-btn p {
    margin-bottom: 2px;
}

.sales-report-submit-btn .message-wrapper .message-separator {
    margin-top: 10px;
    margin-bottom: 10px;
}

.sales-report-submit-btn .message-wrapper {
    display: none;
    margin-bottom: 30px;
}

.wide-btn {
    width: 90% !important;
    margin-top: 20px;
}

.disabled {
    opacity: 0.4;
    pointer-events: None;
}

.error-msg {
    color: red;
    font-size:12px;
}

.updated-item {
    background-color: rgba(147, 250, 165, 0.5);
}

.no-input {
    color: gray;
}
</style>