<template>
    <v-dialog persistent max-width="400" v-model="dialog">
        <v-card class="rounded-xl">
            <v-card-title><v-spacer class="text-center">Location Service</v-spacer></v-card-title>
            <v-card-text class="py-2 text-left">
                <span class="text-subtitle-1 mb-2">System is unable to locate your location right now. Please make sure that you are:</span>
                <ul>
                    <li class="text-subtitle-1">Enable the geo-location service in the system settings</li>
                    <li class="text-subtitle-1">Enable the geo-location service in this browser settings</li>
                </ul>
            </v-card-text>
            <v-card-actions class="justify-space-around">
                <v-btn text :ripple="false" @click="close">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>

export default {
    name: 'ShiftLocationTipsDialog',
    data() {
        return {
            dialog: false,
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
        }
    }
}

</script>


<style scoped>
.text-subtitle-1 {
    line-height: 1.2rem !important;
}

ul {
    list-style: disc !important;
    padding-left: 14px !important;
}
</style>