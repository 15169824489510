<template>
    <div>
        <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" v-model="dialog">
            <v-card>
                <v-toolbar color="primary">
                    <v-btn icon @click="close" class="white--text">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title class="white--text">Report Sales</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-img contain max-width="100%" max-height="120" class="my-5" :src="object.image_url" />
                <v-card-title class="justify-center" v-text="object.name"></v-card-title>
                <p class="px-4 mb-1 d-flex justify-space-between">
                    <span class="text-subtitle-1">Price</span>
                    <span v-text="object.price"></span>
                </p>
                <p class="px-4 mb-1 d-flex justify-space-between" v-if="object.quantity > 0">
                    <span class="text-subtitle-1">Previous Sales</span>
                    <span v-text="object.quantity"></span>
                </p>
                <p class="px-4 mb-1 d-flex justify-space-between">
                    <span class="text-subtitle-1" v-text="object.quantity > 0?'New Sales':'Sales'"></span>
                    <span v-text="object.new_quantity"></span>
                </p>
                <van-number-keyboard show theme="custom" close-button-text="Submit" extra-key="." :close-button-loading="submitting" @input="keyboardInputHandler" @delete="keyboardDeleteHandler" @close="saveQuantity"></van-number-keyboard>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>

import { Toast } from 'vant';

export default {
    name: 'ShiftSalesUpdateDialog',
    data() {
        return {
            dialog: false,
            submitting: false,
            object: {
                code: '',
                id: '',
                image_url: '',
                name: '',
                price: '',
                quantity: 0,
                new_quantity: ''
            }
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
            this.object.new_quantity = ''
        },
        updateObject: function(obj){
            this.object = { 'id': obj.id, 'code': this.$route.params.code, 'image_url': obj.image_url, 'name': obj.name,
                            'price': obj.price, 'quantity': obj.quantity, 'new_quantity': '' }
        },
        keyboardInputHandler: function(value){
            this.object.new_quantity += value.toString()
        },
        keyboardDeleteHandler: function(){
            this.object.new_quantity = this.object.new_quantity.substring(0, this.object.new_quantity.length - 1)
        },
        saveQuantity: function(){
            if(!this.object.new_quantity){
                Toast.fail({ message: 'Please input sales' })
                return
            }

            this.submitting = true
            const object = { 'code': this.object.code, 'sku': this.object.id, 'quantity': this.object.new_quantity }
            this.$store.dispatch('mobile/shift/updateShiftSales', object).then((response) => {
                this.submitting = false
                this.$emit('updated', response.data.object)
                this.close()
            }).catch(() => {
                this.submitting = false
            })
            this.$emit('updated', object)
            this.close()
        }
    }
}

</script>

<style scoped>

/deep/ .van-key--blue {
    background-color: #f08300 !important;
}

/deep/ .v-list-item {
    min-height: 32px !important;
}

</style>