<template>
    <div>
        <v-dialog persistent max-width="764px" v-model="dialog">
            <v-card>
                <v-card-title class="justify-center" v-text="title"></v-card-title>
                <v-card-text class="pb-0 text-center">
                    <p v-text="message"></p>
                </v-card-text>
                <v-card-actions class="">
                    <v-btn text @click="close">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="red" @click="confirm" v-text="confirmButtonText"></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>


export default {
    name: 'ShiftFeedbackConfirmationDialog',
    props: {
        title: {
            type: String,
            required: true,
            default: ''
        },
        message: {
            type: String,
            required: true,
            default: ''
        },
        confirmButtonText: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return {
            dialog: false
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
            this.$emit('closed')
        },
        confirm: function(){
            this.dialog = false
            this.$emit('confirmed')
        }
    }
}

</script>