<template>
    <v-dialog persistent max-width="400" v-model="dialog">
        <v-card class="rounded-xl">
            <v-card-title><v-spacer class="text-center">Notice</v-spacer></v-card-title>
            <v-card-text class="py-2 text-left">
                <span class="text-subtitle-1 mb-2">Please fill in the sales for each SKU. Please fill "0" if there is no sales yet</span>
            </v-card-text>
            <v-card-actions class="justify-space-around">
                <v-btn text :ripple="false" @click="close">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>

export default {
    name: 'ShiftSalesTipsDialog',
    data() {
        return {
            dialog: false,
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
        }
    }
}

</script>


<style scoped>
.text-subtitle-1 {
    line-height: 1.2rem !important;
}

</style>