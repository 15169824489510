<template>
    <div>
        <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" v-model="dialog">
            <v-form lazy-validation v-model="valid">
                <v-card :style="getStyle()" class="elevation-0">
                    <v-toolbar color="primary">
                        <v-btn icon @click="close" class="white--text">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-toolbar-title class="white--text">Take Activity Photo</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <div class="d-flex flex-column justify-space-between">
                        <v-container fluid>
                            <v-layout justify-center class="my-4">
                                <van-uploader max-count="1" capture="camera" preview-size="160px" :disabled="loading" :before-read="beforeReader" :after-read="afterReader" v-model="images"></van-uploader>
                            </v-layout>
                            <v-row>
                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <v-btn block large class="rounded-xl elevation-0" color="primary" :ripple="false" :disabled="images.length === 0" @click="openConfirmationDialog">Submit</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </v-card>
            </v-form>
        </v-dialog>
        <confirmation-dialog ref="confirmationDialog" title="Submit Activity Photo" message="Do you want to submit this activity photo? Once you submit this activity photo, you are no longer to edit it again." confirm-button-text="Submit" @confirmed="openQuestionDialog"></confirmation-dialog>
        <shift-location-question-dialog ref="questionDialog" :general_choice="general_choice" :booth_location_feedback="booth_location_feedback" confirm-button-text="Submit" @confirmed="submitActivity"></shift-location-question-dialog>
    </div>
</template>


<script>

import { copyObject } from '@/utils/helper'
import ShiftAttendanceConfirmationDialog from './ShiftAttendanceConfirmationDialog'
import ShiftLocationQuestionDialog from './ShiftLocationQuestionDialog'
import { Toast } from 'vant'

export default {
    name: 'ShiftAttendanceCreateDialog',
    components: {
        confirmationDialog: ShiftAttendanceConfirmationDialog,
        shiftLocationQuestionDialog: ShiftLocationQuestionDialog
    },
    props: {
        'code': {
            type: String,
            required: false,
            default: ''
        },
        'general_choice': {
            type: Array,
            required: false,
            default: () => []
        },
        'booth_location_feedback': {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            loading: false,
            valid: true,
            dialog: false,
            images: [],
            object: {
                message: '',
                images: []
            }
        }
    },
    computed: {
        confirmationDialog() {
            return this.$refs.confirmationDialog
        },
        questionDialog() {
            return this.$refs.questionDialog
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        openQuestionDialog: function() {
            this.questionDialog.open()
        },
        openConfirmationDialog: function() {
            this.confirmationDialog.open()
        },
        close: function() {
            this.dialog = false
            this.images = []
            this.object = { 'message': '', images: [] }
        },
        beforeReader: function(file) {
            const regex = /(.jpg|.jpeg|.png|.bmp)$/
            if(!regex.test(file.type)){
                return false
            }else{
                return true
            }
        },
        afterReader: function(file) {
            const maxSize = 800
            let canvas = document.createElement('canvas')
            let image = new Image()
            image.src = file.content
            image.onload = () => {
                var width = image.width, height = image.height
                if(width > height && width > maxSize){
                    height *= maxSize / width
                    width = maxSize
                }else if(width <= height && height > maxSize){
                    width *= maxSize / height;
                    height = maxSize
                }

                canvas.width = width
                canvas.height = height
                canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                this.object.images.push(canvas.toDataURL('image/jpeg'))
            }
        },
        submitActivity: function(item) {
            this.loading = true
            var object = copyObject(this.object, ['images'])
            object['code'] = this.code
            object['image'] = this.object.images.length>0?this.object.images[0]:''
            object['booth_location'] = item.object
            object['booth_location_feedback'] = item.feedback
            object['is_competitor'] = item.is_competitor
            object['competitor_brand'] = item.competitor_brand
            object['is_competitor_promotion'] = item.is_competitor_promotion
            object['competitor_promotion'] = item.competitor_promotion

            Toast.loading({
                message: 'Submitting...',
                forbidClick: true,
                duration: 0
            })

            this.$store.dispatch('mobile/shift/createShiftAttendance', object).then((response) => {
                this.loading = false
                this.$emit('created', { object: response.data.object.shift, attendance: response.data.object.attendance,  message: response.message })
                this.close()
                Toast.clear()
            }).catch(() => {
                Toast.loading({
                    message: 'Unable to submit photo',
                    forbidClick: true,
                    duration: 3000
                })
                this.loading = false
            })

        },
        getStyle: function(bottomHeight=0) {
            const minHeight = (window.innerHeight - bottomHeight).toString() + "px";
            return {"min-height": minHeight}
        },
        submitFeedbackActivity: function(item) {
            console.log(item.object)
            this.$store.dispatch('mobile/shift/createShiftAttendance', {booth_location: item.object, booth_location_feedback: item.feedback}).then((response) => {
                this.loading = false
                this.$emit('created', {object: response.data.object, message: response.message})
                this.close()
            })
        }
    }
}

</script>

<style scoped>

/deep/ .van-key--blue {
    background-color: #f08300 !important;
}

/deep/ .v-list-item {
    min-height: 32px !important;
}

/deep/ .van-uploader__upload-icon {
    font-size: 48px !important;
}

</style>