<template>
    <div>
        <div>
            <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" v-model="dialog">
                <v-card :style="getStyle()" class="elevation-0">
                    <v-toolbar color="primary">
                        <v-btn icon @click="close" class="white--text">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-toolbar-title class="white--text">Report Feedback Review</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <div class="d-flex flex-column justify-space-between" :style="getStyle(56)">
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <display-label class="my-4 text-message" label="What do you want to say?" :text="object.message"></display-label>
                                </v-col>
                            </v-row>
                            <v-row v-if="object.images.length > 0">
                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <display-label label="Uploaded Photo"></display-label>
                                </v-col>
                                <v-col cols="4" xs="4" sm="4" md="4" lg="4" xl="4" :key="index" v-for="(image, index) in object.images">
                                    <v-img :src="image.thumbnail_url" class="mx-2" ></v-img>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container fluid v-if="deletable">
                            <v-row>
                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <v-btn block large class="rounded-xl white--text elevation-0" color="red" :ripple="false" :loading="loading" @click="openConfirmationDialog">Delete</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </v-card>
            </v-dialog>
            <confirmation-dialog ref="confirmationDialog" title="Delete Feedback" message="Do you want to delete this feedback?" confirm-button-text="Delete" @confirmed="remove"></confirmation-dialog>
        </div>
    </div>
</template>


<script>


import DisplayLabel from '../../../shared/DisplayLabel.vue'
import ShiftFeedbackConfirmationDialog from './ShiftFeedbackConfirmationDialog'

export default {
    name: 'ShiftFeedbackDeleteDialog',
    components: {
        displayLabel: DisplayLabel,
        confirmationDialog: ShiftFeedbackConfirmationDialog
    },
    props: {
        deletable: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            dialog: false,
            object: {
                message: '',
                images: []
            }
        }
    },
    computed: {
        confirmationDialog() {
            return this.$refs.confirmationDialog
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        openConfirmationDialog: function() {
            this.confirmationDialog.open()
        },
        close: function() {
            this.dialog = false
            this.images = []
            this.object = { 'message': '', images: [] }
        },
        updateObject: function(item) {
            this.object = item
        },
        remove: function() {
            this.loading = true

            this.$store.dispatch('mobile/shift/deleteShiftFeedback', {'code': this.object.id}).then(() => {
                this.loading = false
                this.close()
                this.$emit('deleted')
            }).catch(() => {
                this.loading = false
            })
        },
        getStyle: function(bottomHeight=0) {
            const minHeight = (window.innerHeight - bottomHeight).toString() + "px";
            return {"min-height": minHeight}
        }
    }
}

</script>

<style scoped>

/deep/ .van-key--blue {
    background-color: #f08300 !important;
}

/deep/ .v-list-item {
    min-height: 32px !important;
}

/deep/ .text-label {
    font-size: 1.25rem !important;
}

/deep/ .text-message .text-title {
    margin-top: 8px !important;
}

</style>