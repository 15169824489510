<template>
    <div>
        <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" v-model="dialog">
            <v-form lazy-validation v-model="valid">
                <v-card :style="getStyle()" class="elevation-0">
                    <v-toolbar color="primary">
                        <v-btn icon @click="close" class="white--text">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-toolbar-title class="white--text">Report Feedback</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <div class="d-flex flex-column justify-space-between" :style="getStyle(56)">
                        <v-container fluid>
                            <v-row fill-height>
                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <v-textarea rows="8" class="no-resize" :disabled="loading" label="What do you want to say?" v-model="object.message"></v-textarea>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <p>Upload Photo (Optional)</p>
                                    <van-uploader max-count="3" :disabled="loading" :after-read="afterReadHandler" v-model="images"></van-uploader>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <v-btn block large class="rounded-xl elevation-0" color="primary" :ripple="false" :disabled="!object.message" @click="openConfirmationDialog">Submit</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </v-card>
            </v-form>
        </v-dialog>
        <confirmation-dialog ref="confirmationDialog" title="Submit Feedback" message="Do you want to submit this feedback?" confirm-button-text="Submit" @confirmed="submitFeedback"></confirmation-dialog>
    </div>
</template>


<script>

import { copyObject } from '@/utils/helper'
import { stringifyJSON } from '@/utils/encodes'
import ShiftFeedbackConfirmationDialog from './ShiftFeedbackConfirmationDialog'
import { Toast } from 'vant'

export default {
    name: 'ShiftFeedbackCreateDialog',
    components: {
        confirmationDialog: ShiftFeedbackConfirmationDialog
    },
    props: {
        'code': {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            loading: false,
            valid: true,
            dialog: false,
            images: [],
            object: {
                message: '',
                images: []
            }
        }
    },
    computed: {
        confirmationDialog() {
            return this.$refs.confirmationDialog
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        openConfirmationDialog: function() {
            this.confirmationDialog.open()
        },
        close: function() {
            this.dialog = false
            this.images = []
            this.object = { 'message': '', images: [] }
        },
        afterReadHandler: function(file) {
            const maxSize = 600
            let canvas = document.createElement('canvas')
            let image = new Image()
            image.src = file.content
            image.onload = () => {
                var width = image.width, height = image.height
                if(width > height && width > maxSize){
                    height *= maxSize / width
                    width = maxSize
                }else if(width <= height && height > maxSize){
                    width *= maxSize / height;
                    height = maxSize
                }

                canvas.width = width
                canvas.height = height
                canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                this.object.images.push(canvas.toDataURL('image/jpeg'))
            }
        },
        submitFeedback: function() {
            this.loading = true
            var object = copyObject(this.object, ['images'])
            object['shift'] = this.code
            object['images'] = stringifyJSON(this.object.images)

            Toast.loading({
                message: 'Submitting...',
                forbidClick: true,
                duration: 0
            })

            this.$store.dispatch('mobile/shift/createShiftFeedback', object).then((response) => {
                this.loading = false
                this.$emit('created', response.data.object)
                this.close()
                Toast.clear()
            }).catch(() => {
                Toast.loading({
                    message: 'Unable to submit photos',
                    forbidClick: true,
                    duration: 3000
                })
                this.loading = false
            })

        },
        getStyle: function(bottomHeight=0) {
            const minHeight = (window.innerHeight - bottomHeight).toString() + "px";
            return {"min-height": minHeight}
        }
    }
}

</script>

<style scoped>

/deep/ .van-key--blue {
    background-color: #f08300 !important;
}

/deep/ .v-list-item {
    min-height: 32px !important;
}

</style>