<template>
    <v-dialog persistent max-width="400" v-model="dialog">
        <v-card class="rounded-xl" v-if="object.status=='bi'">
            <v-card-title><v-spacer class="text-center">Break In Survey</v-spacer></v-card-title>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <span class="text-subtitle-1 text-separator" v-text="getText()"></span>
                <span class="text-subtitle-1 text-separator" v-text="getCnText()"></span>
                <v-radio-group row class="mt-3" v-model="object.crowd_flow_before_break_feedback" v-for="(option, index) in crowdbe" :key="index">
                    <v-radio class="mb-3" :key="index" :ripple="false" :label="option.text" :value="option.value"></v-radio>
                    <div class="cn-text">
                        <label>{{ option.cn_text }}</label>
                    </div>
                </v-radio-group>
            </v-col>
            <v-card-actions class="justify-space-around">
                <v-btn text :ripple="false" @click="close">Later (之后)</v-btn>
                <v-btn text color="primary" :disabled="!object.crowd_flow_before_break_feedback" :ripple="false" @click="save" v-text="getButtonText()"></v-btn>
            </v-card-actions>
        </v-card>

         <v-card class="rounded-xl" v-if="object.status=='co'">
            <v-card-title><v-spacer class="text-center">Clock Out Survey</v-spacer></v-card-title>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <span class="text-subtitle-1 text-separator" v-text="getText()"></span>
                <span class="text-subtitle-1 text-separator" v-text="getCnText()"></span>
                <v-radio-group row class="mt-3" v-model="object.crowd_flow_after_break_feedback" v-for="(option, index) in crowdaf" :key="index">
                    <v-radio class="mb-3" :key="index" :ripple="false" :label="option.text" :value="option.value"></v-radio>
                    <div class="cn-text">
                        <label>{{ option.cn_text }}</label>
                    </div>
                </v-radio-group>
            </v-col>
            <v-card-actions class="justify-space-around">
                <v-btn text :ripple="false" @click="close">Later (之后)</v-btn>
                <v-btn text color="primary" :disabled="!object.crowd_flow_after_break_feedback" :ripple="false" @click="save" v-text="getButtonText()"></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>

export default {
    name: 'ShiftBreakSurveyDialog',
    props: {
        crowdbe: {
            type: Array,
            required: false,
            default: () => []
        },
        crowdaf: {
            type: Array,
            required: false,
            default: () => []
        },
    },
    data() {
        return {
            dialog: false,
            object: {
                crowd_flow_before_break_feedback: '',
                crowd_flow_after_break_feedback: '',
            },
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
        },
        save: function() {
            if (this.object.status=='bi'){
                this.$emit('submitted', {crowd_flow_before_break_feedback: this.object.crowd_flow_before_break_feedback,
                                    status: this.object.status})
            }
            else if(this.object.status=='co') {
                this.$emit('submitted', {crowd_flow_after_break_feedback: this.object.crowd_flow_after_break_feedback,
                                    status: this.object.status})
            }
        },
        updateObject: function(obj){
            this.object = obj
        },
        getText: function(){
            const texts = {"bi": "How was the crowd flow before the break?", "co": "How was the crowd flow after the break?"};
            return texts[this.object.status]
        },
        getCnText: function() {
            const texts = {"bi": "你休息前店里的人流如何?", "co": "休息后，店里的人流如何?"};
            return texts[this.object.status]
        },
        getButtonText: function() {
            const texts = { "bi": "Submit (提交)", "co": "Submit (提交)"}
            return texts[this.object.status]
        }
    }
}

</script>

<style>
    .cn-text {
        padding-left: 30px;
    }

    .text-separator {
        display: block;
    }
</style>