<template>
    <div>
        <app-top-bar title="Attendance Photo" left-icon="mdi-arrow-left" @leftIconEvent="back"></app-top-bar>
        <van-pull-refresh pulling-text="Pull to refresh" loosing-text="Release to refresh" loading-text="Loading..." @refresh="refresh" v-model="refreshing">
            <v-layout align-center justify-center :style="getStyle()" v-if="loading">
                <v-progress-circular indeterminate :width="3" :size="60" color="primary"></v-progress-circular>
            </v-layout>
            <div v-if="!loading">
                <v-layout align-center justify-center :style="getStyle()" v-if="!attendance.image_url">
                    <div v-if="!check_in" class="text-center">
                        <p>You cannot submit photo attendance due to not Checked-In yet</p>
                    </div>
                    <div v-else class="photo-attendance-wrapper">
                        <p v-text="message"></p>
                        <v-btn small color="primary" class="elevation-0 rounded-lg" :ripple="false" v-if="attendance.can_take_photo === true" @click="openCreateDialog">Submit Photo</v-btn>
                    </div>
                </v-layout>
                <v-container fluid class="pa-0" :style="getStyle()" v-if="attendance.image_url">
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12">
                            <v-sheet :ripple="false" class="sheet px-4 py-2 elevation-0">
                                <p class="mb-1 text-subtitle-2 grey--text lighten--4" v-text="attendance.created_on"></p>
                                <p class="d-flex mb-1">
                                    <v-img width="50" max-width="50%" :src="attendance.thumbnail_url"></v-img>
                                </p>
                            </v-sheet>
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </van-pull-refresh>
        <create-dialog ref="createDialog" :code="code" :general_choice="shift_survey.general_choices" :booth_location_feedback="shift_survey.booth_location_feedback" @created="updateAttendance"></create-dialog>
    </div>
</template>


<script>

import { delay } from '@/utils/helper'
import { redirectPathTo } from '@/utils/route'
import AppTopBar from '../../layout/AppTopBar.vue'
import ShiftAttendanceCreateDialog from './ShiftAttendanceCreateDialog.vue'

export default {
    name: 'ShiftAttendancePanel',
    components: {
        appTopBar: AppTopBar,
        createDialog: ShiftAttendanceCreateDialog,
    },
    props: {
        code: {
            type: String,
            required: false,
            default: ''
        },
        forceUpdate: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            loading: true,
            refreshing: false,
            selectedIndex: -1,
            message: '',
            items: [],
            filter: {},
            attendance: {
                can_take_photo: false,
            },
            shift_survey: {},
            object: {
                is_closed: true
            },
            check_in: false
        }
    },
    computed: {
        createDialog() {
            return this.$refs.createDialog
        }
    },
    mounted: function() {
        window.scrollTo(0, 0)
        this.get()
    },
    methods: {
        back: function() {
            redirectPathTo(this.$router, this.$route.meta.back_url)
        },
        get: function(loading=true) {
            this.loading = loading
            delay(() => {
                this.$store.dispatch('mobile/shift/getShiftAttendance', { code: this.code }).then((response) => {
                    this.loading = false
                    this.object = response.data.object.shift
                    this.attendance = response.data.object.attendance
                    this.shift_survey = response.data.object.shift_survey
                    this.message = response.message
                    this.check_in = response.data.object.is_ci
                }).catch(() => {
                    this.loading = false
                })
            }, 500)
		},
		refresh() {
            delay(() => {
                this.get(false)
                this.refreshing = false
            }, 1000)
        },
        reset: function() {
            this.loading = true
        },
        getStyle: function() {
            return {"min-height": (window.innerHeight - 56).toString() + "px"}
        },
        openCreateDialog: function(){
            this.createDialog.open()
        },
        updateAttendance: function(item) {
            this.$set(this, 'object', item.object)
            this.$set(this, 'attendance', item.attendance)
        }
    },
    watch: {
        forceUpdate: function(newValue){
            if(newValue && !this.object.is_closed){
                this.get()
            }
        }
    }
}

</script>

<style>
    .photo-attendance-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
</style>