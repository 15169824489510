<template>
    <v-dialog persistent max-width="400" v-model="dialog">
        <v-card class="rounded-xl">
            <v-card-title><v-spacer class="text-center">Activation Feedback</v-spacer></v-card-title>
            <v-col class="pb-0 pt-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <display-label label="Is working position good?"></display-label>
                <display-label label="工作地点好吗？"></display-label>
                <v-radio-group row class="mt-3" v-model="object.location">
                    <v-radio :key="index" @click="clearCheckbox('l')" :ripple="false" :label="option.text" :value="option.value" v-for="(option, index) in general_choice"></v-radio>
                </v-radio-group>
             </v-col>
             <v-col class="pb-0 pt-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="object.location=='N'">
                <display-label label="Why? (为什么?)"></display-label>
                <div v-for="(option, index) in booth_location_feedback" :key="index">
                    <v-checkbox class="mb-5" v-model="location_feedback" :key="index" :ripple="false" :label="option.text" :value="option.value"></v-checkbox>
                    <div class="cn-label-wrapper">
                        <label :label="option.cn_text">{{option.cn_text}}</label>
                    </div>
                </div>
             </v-col>
             <v-col class="pb-0 pt-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <display-label label="Was there a promotion happening for your brand today?"></display-label>
                <display-label label="你的品牌今天有促销活动吗？"></display-label>
                <v-radio-group row class="mt-3" v-model="object.is_promotion">
                    <div v-for="(option, index) in promotion" :key="index">
                        <v-radio class="mb-5" :key="index" @click="clearCheckbox('p')" :ripple="false" :label="option.text" :value="option.value"></v-radio>
                        <div class="cn-label">
                            <label :label="option.cn_text">{{option.cn_text}}</label>
                        </div>
                    </div>
                </v-radio-group>
             </v-col>
             <v-col class="pb-0 pt-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="object.is_promotion=='YES_PROMOTION_NOT_TOO_ATTRACTIVE'">
                <display-label label="Why? (You may select more than one option)"></display-label>
                <display-label label="为什么?（您可以选择多个选项）"></display-label>
                <div v-for="(option, index) in reason" :key="index">
                    <v-checkbox  v-model="promotion_feedback" class="mb-5" :key="index" :ripple="false" :label="option.text" :value="option.value"></v-checkbox>
                    <div class="cn-label-wrapper">
                        {{ option.cn_text }}
                    </div>
                </div>
             </v-col>
             <v-col class="pb-0 pt-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <display-label label="When you share about the BRANDS/PRODUCT that you are presenting, what are the response?"></display-label>
                <display-label label="当您分享您所展示的品牌/产品时，顾客的反应是什么？"></display-label>
                <v-radio-group row class="mt-3" v-model="object.brand_awareness">
                    <div v-for="(option, index) in awareness" :key="index">
                        <v-radio class="mb-5" :key="index" :ripple="false" :label="option.text" :value="option.value"></v-radio>
                        <div class="cn-label">
                            <label :label="option.cn_text">{{option.cn_text}}</label>
                        </div>
                    </div>
                </v-radio-group>
             </v-col>
            <v-card-actions class="justify-space-around">
                <v-btn text :ripple="false" @click="close">Later (之后)</v-btn>
                <v-btn text color="primary" :disabled="!object.is_promotion || !object.location || !object.brand_awareness" :ripple="false" @click="save" v-text="getButtonText()"></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import DisplayLabel from '../../../shared/DisplayLabel.vue'

export default {
    name: 'ShiftSalesSurveyDialog',
    components: {
        displayLabel: DisplayLabel,
    },
    props: {
        general_choice: {
            type: Array,
            required: false,
            default: () => []
        },
        booth_location_feedback: {
            type: Array,
            required: false,
            default: () => []
        },
        promotion: {
            type: Array,
            required: false,
            default: () => []
        },
        reason: {
            type: Array,
            required: false,
            default: () => []
        },
        awareness: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            dialog: false,
            object: {
                is_promotion: '',
                location: '',
                brand_awareness: ''
            },
            location_feedback: [],
            promotion_feedback: [],
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
        },
        save: function() {
            this.$emit('submitted', {is_promotion: this.object.is_promotion, promotion_feedback: this.promotion_feedback,
                                    location: this.object.location, location_feedback: this.location_feedback, 
                                    brand_awareness: this.object.brand_awareness})
        },
        updateObject: function(obj){
            this.object = obj
        },
        getButtonText: function() {
            const texts = "Submit (提交)"
            return texts
        },
        clearCheckbox: function(item) {
            if(item == 'l') {
                this.location_feedback=[]
            }
            else if (item == 'p') {
                this.promotion_feedback=[]
            }
        }
    }
}

</script>

<style scoped>

.text-fields-row {
  display: flex;
}

.v-text-field {
    padding-top: 0px !important;
    margin-top: 0px !important;
}

.cn-label {
    padding-left: 30px;
    margin-top: -15px;
    margin-bottom: 10px;
}

.cn-label-wrapper {
    padding-left: 30px;
    margin-top: -30px;
    margin-bottom: 10px;
}
</style>