<template>
    <div></div>
</template>


<script>

export default {
    name: 'GeoLocationReader',
    props: {
        auto: {
            type: Boolean,
            required: false,
            default: false
        },
        highAccuracy: {
            type: Boolean,
            required: false,
            default: false
        },
        timeout: {
            type: Number,
            required: false,
            default: 5000
        },
        cacheDuration: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data() {
        return {
            watchId: null
        }
    },
    mounted: function() {
        if(this.auto){
            this.getLocation()
        }
    },
    methods: {
        getLocation: function() {
            this.$getLocation({
                enableHighAccuracy: this.highAccuracy,
                timeout: this.timeout,
                maximumAge: this.cacheDuration
            }).then((data) => {
                this.$emit('updateLocation', data)
            }).catch((error) => {
                this.$emit('rejectLocation', error)
            })
        },
        watchLocation: function() {
            this.watchId = this.$watchLocation({
                enableHighAccuracy: this.highAccuracy,
                timeout: this.timeout,
                maximumAge: this.cacheDuration
            }).then((data) => {
                this.$emit('updateLocation', data)
            })
        },
        removeWatchLocation: function() {
            if(this.watchId){
                this.$clearLocationWatch(this.watchId)
            }
        }
    }
}

</script>